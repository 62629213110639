import { object } from 'prop-types';
import * as styles from './index.module.scss';
import { removingWordFree } from '../../../helpers';
import { Wrap } from '../../sections-wrap';

function StaticPagesTemplate({ data }) {
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.main}>
          <article className={styles.entry}>
            <header>
              <h1 className={styles.title}>{data?.wpPage?.title}</h1>
            </header>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={
                  { __html: removingWordFree(data?.wpPage?.content) }
                }
            />
          </article>
        </main>
      </Wrap>
    </div>
  );
}

StaticPagesTemplate.propTypes = {
  data: object,
};

StaticPagesTemplate.defaultProps = {
  data: {},
};

export { StaticPagesTemplate };
