import { object } from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { createPostBreadCrumb, createPostsJSONLD } from '../../helpers';
import {
  createSiteWideSchema,
  createVideoSchema,
  getPostMissingBreadcrumb,
  removingWordFree,
} from '../../helpers/misc';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    contentfulResourceSitewideSchema {
      currenciesAccepted
      areaServed
      email
      foundingDate
      legalName
      name
      numberOfEmployees
      openingHours
      priceRange
      email
      image {
        url
        description
      }
      logo {
        url
        description
      }
      locations {
        addressLocality
        addressRegion
        postalCode
        streetAddress
        telephone
        locationTitle
        locationImages {
          description
          url
        }
      }
      telephone
    }
  }
`;

const removeHeadFromOldSchema = (schema) => {
  let cleanSchema = schema;
  cleanSchema = cleanSchema.replaceAll(
    '<script type="application/ld+json">',
    '',
  );
  cleanSchema = cleanSchema.replaceAll('</script>', '');
  return cleanSchema;
};

const applyMetaTagNoIndex = (url = '') => {
  if (!url) return false;

  const urlParts = url.split('/').filter(Boolean);
  if (urlParts.length === 0) return false;

  const firstPartUrl = urlParts[0];
  const lastPartUrl = urlParts[urlParts.length - 1];

  return firstPartUrl.includes('tag') || lastPartUrl.includes('step');
};

function Head({ data, pageContext }) {
  let seo = {};
  if (pageContext?.seo && data) {
    seo = { ...data?.wpPage?.seo, ...pageContext.seo };
  } else if (pageContext?.seo) {
    seo = pageContext.seo;
  } else if (data) {
    if (pageContext.isPost) {
      const { wpPage } = data;
      const updatedSeo = { ...wpPage.seo };
      updatedSeo.missingBreadcrumb = getPostMissingBreadcrumb(wpPage);
      updatedSeo.tags = wpPage.tags?.nodes.map((node) => node.name);
      updatedSeo.schemaMarkup = wpPage.schemaMarkup;
      seo = updatedSeo;
    } else if (data.pageData) {
      seo = {
        title: data.pageData.seo?.pageTitle,
        metaDesc: data.pageData.seo?.metaDescription,
      };
    } else {
      const { wpPage } = data;
      const isSeoCalculator = wpPage?.titleSchema || wpPage?.descriptionSchema;
      if (wpPage) {
        if (wpPage.seo) {
          seo = wpPage.seo;
        } else if (isSeoCalculator) {
          seo = {
            title: wpPage.titleSchema,
            metaDesc: wpPage.descriptionSchema,
          };
        }
      }
    }
  }
  const { site, contentfulResourceSitewideSchema } = useStaticQuery(query);
  const { title } = site.siteMetadata;

  const getTitle = () => {
    if (
      pageContext?.uri?.includes('/category')
      || pageContext?.uri?.includes('/blog')
    ) {
      return `Legal Insights - ${`${
        data?.wpPage?.name || ''
      } `}Blog Archive - Page ${pageContext.currentPage}`;
    }
    return seo?.title || title;
  };

  const defaultTitle = getTitle();
  const ogImage = seo?.opengraphImage;
  const logo = contentfulResourceSitewideSchema.image.url;

  const useNewPostSchema = seo?.schemaMarkup
    && seo?.schemaMarkup.eaPostSchemaGenre
    && seo?.schemaMarkup.eaPostSchemaWordCount;

  /* Removes last / because Gatsby urls starts with / (only old schema) */
  const siteUrl = process.env.GATSBY_SITE_URL.slice(0, -1);

  const addDomainToJSONLD = () => {
    if (seo.schema.raw) {
      let schema;
      schema = seo.schema.raw.replaceAll('#website', '');
      schema = seo.schema.raw.replaceAll('#webpage', '');
      return schema.replace(
        /(:"\/)/g,
        () => `:"${process.env.GATSBY_SITE_URL}`,
      );
    }
    return '';
  };

  return (
    <>
      <title>{defaultTitle}</title>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-J8KTQ0HZ9Y"
      />
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-J8KTQ0HZ9Y');
        `}
      </script>
      <script>
        {`
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WVTGX8F');
`}
      </script>
      {seo?.metaDesc && (
        <meta name="description" content={removingWordFree(seo?.metaDesc)} />
      )}
      {applyMetaTagNoIndex(pageContext?.uri) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="og:locale" content="en_US" />
      {seo?.opengraphPublishedTime && (
        <meta
          name="article:published_time"
          content={seo?.opengraphPublishedTime}
        />
      )}
      {seo?.opengraphModifiedTime && (
        <meta
          name="article:modified_time"
          content={seo?.opengraphModifiedTime}
        />
      )}
      {seo?.opengraphPublisher && (
        <meta name="article:publisher" content={seo?.opengraphPublisher} />
      )}
      {seo?.opengraphAuthor && (
        <meta name="article:author" content={seo?.opengraphAuthor} />
      )}

      {seo?.opengraphDescription && (
        <meta
          name="og:description"
          content={removingWordFree(seo?.opengraphDescription)}
        />
      )}
      {seo?.opengraphSiteName && (
        <meta name="og:site_name" content={seo?.opengraphSiteName} />
      )}
      {seo?.opengraphTitle && <meta name="og:title" content={defaultTitle} />}
      {seo?.opengraphType && (
        <meta name="og:type" content={seo?.opengraphType} />
      )}
      {seo?.opengraphUrl && (
        <meta name="og:url" content={siteUrl + seo.opengraphUrl} />
      )}

      {ogImage?.sourceUrl ? (
        <meta property="twitter:image" content={ogImage.sourceUrl} />
      ) : (
        <meta property="twitter:image" content={logo} />
      )}
      {ogImage?.altText && (
        <meta property="twitter:alt" content={ogImage.altText} />
      )}
      {seo?.opengraphTitle && (
        <meta name="twitter:title" content={defaultTitle} />
      )}
      {seo?.opengraphDescription && (
        <meta
          name="twitter:description"
          content={removingWordFree(seo.opengraphDescription)}
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />

      {ogImage?.sourceUrl ? (
        <meta property="og:image" content={ogImage.sourceUrl} />
      ) : (
        <meta property="og:image" content={logo} />
      )}
      {ogImage?.altText && <meta property="og:alt" content={ogImage.altText} />}

      {contentfulResourceSitewideSchema && (
        <script type="application/ld+json">
          {createSiteWideSchema(contentfulResourceSitewideSchema, siteUrl)}
        </script>
      )}

      {seo?.schema && !useNewPostSchema && !pageContext?.isPost && (
        <script type="application/ld+json">{addDomainToJSONLD()}</script>
      )}

      {data.wpPage?.schemaMarkup?.eaPostSchemaMarkup
        && !useNewPostSchema
        && pageContext?.isPost && (
          <script type="application/ld+json">
            {removeHeadFromOldSchema(
              data.wpPage.schemaMarkup.eaPostSchemaMarkup,
            )}
          </script>
      )}

      {pageContext?.isPost && useNewPostSchema && (
        <script type="application/ld+json">
          {createPostsJSONLD(seo, siteUrl)}
        </script>
      )}
      {pageContext?.isPost && useNewPostSchema && (
        <script type="application/ld+json">
          {createPostBreadCrumb(seo, siteUrl)}
        </script>
      )}

      {seo?.schemaMarkup?.eaPostSchemaVideoUrl && (
        <script type="application/ld+json">{createVideoSchema(seo)}</script>
      )}
    </>
  );
}

Head.propTypes = {
  data: object,
  pageContext: object,
};

Head.defaultProps = {
  data: null,
  pageContext: null,
};

export { Head };
